@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo-container {
  /* height: 7vmin; */
  height: 50px;
  position: absolute;
  top: 32px;
}

.App-logo {
  /* height: 7vmin; */
  height: 50px;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background: linear-gradient(180deg, #000000, #0F0126);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-text {
  font-size: 0.8em;
  margin-bottom: 24px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.App-link {
  color: #61dafb;
}

.QR-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.QR-link {
  text-decoration: none;
  color: inherit;
}

.QR-body {
  width: 256px;
  height: 256px;
  /* background-color: #3B82F6; */
  border-radius: 200;
  overflow: hidden;
  background: linear-gradient(150deg, #4965FF, #CA63FF);
  /* white shadow */
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */